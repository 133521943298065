import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link } from "gatsby";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import PackCard from "~/components/NewPack/PackCard";
import { packAPI } from "~/utils/api/v2";
import Pack from "~/utils/interfaces/Pack";

const PacksIndex = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [allPacks, setAllPacks] = useState<Record<string, Pack[]>>({});
  const [packs, setPacks] = useState<Pack[]>([]);
  const [selectedLocale, setSelectedLocale] = useState<string>("Chile");

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      try {
        const req = await packAPI.list();
        setAllPacks({
          Chile: req.data.filter((pack: Pack) => pack.country === "cl"),
          México: req.data.filter((pack: Pack) => pack.country === "mx"),
        });
      } catch (err) {
        setError(err);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setPacks(allPacks[selectedLocale]);
  }, [selectedLocale, allPacks]);

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <>
          <div className="flex items-center justify-between mb-8">
            <h1>Packs</h1>
            <div className="w-full max-w-[50%]">
              <FormControl fullWidth>
                <InputLabel id="fonasa">País</InputLabel>
                <Select
                  disabled={loading}
                  value={selectedLocale}
                  onChange={(e) => {
                    setSelectedLocale(e.target.value as string);
                  }}
                  fullWidth
                >
                  <MenuItem
                    key="cl"
                    value="Chile"
                  >
                    Chile
                  </MenuItem>
                  <MenuItem
                    key="mx"
                    value="México"
                  >
                    México
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <Link
              to="/new-packs/new"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="outlined"
                size="large"
                color="primary"
              >
                Crear nuevo
              </Button>
            </Link>
          </div>
          <div className="grid grid-cols-2 gap-8">
            {packs?.map((pack: Pack) => (
              <PackCard
                key={pack.id}
                name={pack.name}
                id={pack.id}
              />
            ))}
          </div>
        </>
      )}
    </PrivateRoute>
  );
};

export default PacksIndex;
